.header {
    width: 100%;
    height: 13vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-top: 3vh solid #177247;
    border-bottom: 2px solid #252b2d;
}

.name {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}

.name p {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.25vw;
    font-family: "Times New Roman", Times, serif;
    color: #cb7983;
    padding-left: 0.25vw;
}

.dropdown {
    display: none;
}

.navbar {
    width: 50%;
    height: 100%;
    padding-right: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.navbutton {
    width: 25%;
    height: 100%;
    border: none;
    text-align: center;
    font-size: 1vw;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    transition-duration: 0.5s;
    cursor: pointer;
    background-color: inherit;
}

.navbutton:hover {
    background-color: #177247;
    color: white;
}

@media only screen and (max-width: 1000px) {
    .header {
        height: 30vh;
        align-items: center;
    }

    .name {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .name img {
        height: 75%;
    }

    .name p {
        display: none;
        font-size: 5vw;
        padding: 0vw;
    }

    .dropdown {
        display: contents;
    }

    #dropdown-basic {
        border: none;
        background-color: inherit;
        color: #177247;
    }

    .headericon {
        font-size: 10vw;
    }

    .navbar {
        display: none;
    }
}