.contactusheading {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../Images/ContactUs.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 0 250px rgba(0, 0, 0, 0.5) inset;
}

.contactusheading h1 {
    font-size: 6vw;
    font-family: "Times New Roman", Times, serif;
    color: white;
}

.contactinfo {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vw;
}

.contactinfo h2 {
    padding-bottom: 1vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
}

.links {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.links ul {
    list-style: none;
}

.links li {
    padding-bottom: 1vh;
    text-align: left;
}

.links a {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    line-height: 1.5;
    text-decoration: none;
    color: #438c68;
}

.links a:hover {
    color: #14492a;
}

.contactusicon {
    font-size: 1.5vw;
}

.officehours {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw;
    padding-bottom: 1vh;
    background-color: #7d928b;
}

.officehours h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
}

.hours {
    padding-top: 1vh;
}

.day {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.25vw;
}

.time {
    padding-bottom: 2vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
}

.location {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    padding-left: 25vw;
    padding-right: 25vw;
}

.location h2 {
    padding-bottom: 1vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
}

.location p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    line-height: 1.5;
    text-decoration: none;
}

.location a {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    line-height: 1.5;
    text-decoration: none;
    color: #438c68;
}

.location a:hover {
    color: #14492a;
}

@media only screen and (max-width: 1000px) {
    .contactusheading {
        background-position: center;
    }

    .contactusheading h1 {
        font-size: 10vw;
    }

    .contactinfo {
        height: 30vh;
        padding: 0;
    }

    .contactinfo h2 {
        font-size: 6vw;
    }

    .links {
        width: 100%;
        padding: 0;
    }

    .links li {
        text-align: center;
    }

    .links a {
        font-size: 3vw;
    }

    .contactusicon {
        font-size: 3.5vw;
    }

    .officehours {
        padding: 0vh;
        padding-top: 4vh;
    }

    .officehours h2 {
        font-size: 6vw;
    }

    .day {
        font-size: 3.5vw;
    }

    .time {
        font-size: 3vw;
    }

    .location {
        padding-right: 5vw;
        padding-left: 5vw;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .location h2 {
        font-size: 6vw;
    }

    .location p {
        font-size: 3vw;
    }

    .location a {
        font-size: 3vw;
    }
}