.calendarpageheading {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../Images/CalendarPage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 0 250px rgba(0, 0, 0, 0.5) inset;
}

.calendarpageheading h1 {
    font-size: 6vw;
    font-family: "Times New Roman", Times, serif;
    color: white;
}

.calendar {
    width: 100%;
    padding: 5vw;
}

.rbc-toolbar {
    font-size: 1vw !important;
    font-family: Arial, Helvetica, sans-serif;
}

.rbc-header {
    height: 3vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw !important;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    background-color: #177247;
}

.rbc-time-header-cell {
    height: 100%;
}

.rbc-time-header-cell .rbc-today {
    background-color: #57b689;
}

.rbc-time-slot {
    font-family: Arial, Helvetica, sans-serif;
    color: #177247;
}

.rbc-event,
.rbc-background-event {
    background-color: #177247 !important;
}

@media only screen and (max-width: 1000px) {
    .calendarpageheading {
        background-position: center;
    }

    .calendarpageheading h1 {
        font-size: 10vw;
    }

    .rbc-toolbar {
        font-size: 3vw !important;
    }

    .rbc-header {
        font-size: 3vw !important;
    }

    .rbc-time-slot {
        font-size: 3vw !important;
    }
}