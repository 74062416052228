.leadershipheading {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../Images/Leadership.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 0 250px rgba(0, 0, 0, 0.5) inset;
}

.leadershipheading h1 {
    font-size: 6vw;
    font-family: "Times New Roman", Times, serif;
    color: white;
}

.ourleadership {
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vw;
}

.ourleadership p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    line-height: 1.5;
}

.leadership {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: #7d928b;
}

.ourstaff {
    padding-bottom: 2.5vh;
    padding-right: 10vw;
    padding-left: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ourstaff h2 {
    padding-bottom: 1vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5vw;
    text-decoration: underline;
}

.ourstaff p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75vw;
}

.ourstaff blockquote {
    padding-left: 1.5vw;
}

.ourstaff footer {
    font-weight: bold;
    padding-left: 1.5vw;
}

.founders {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    padding-left: 25vw;
    padding-right: 25vw;
}

.founders h2 {
    padding-bottom: 2.5vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
}

.founders p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    line-height: 1.5;
}

@media only screen and (max-width: 1000px) {
    .leadershipheading {
        background-position: right;
    }

    .leadershipheading h1 {
        font-size: 10vw;
    }

    .ourleadership {
        height: 40vh;
    }

    .ourleadership p {
        font-size: 3vw;
        line-height: 1.5;
    }

    .leadership {
        padding-top: 3vh;
        padding-bottom: 0vh;
        padding-left: 0vw;
        padding-right: 0vw;
    }

    .ourstaff h2 {
        font-size: 4vw;
    }

    .ourstaff p {
        font-size: 3vw;
    }

    .objectives {
        flex-direction: column;
        align-items: center;
        padding: 10vw;
    }

    .founders {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .founders h2 {
        font-size: 4vw;
    }

    .founders p {
        font-size: 3vw;
    }
}