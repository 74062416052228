.footer {
    width: 100%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 5px solid #252b2d;
}

.copyright {
    width: 75vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5%;
}

.footer p {
    margin: 0.5vw;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75vw;
    color: #7b807d;
}

.footerlinks {
    width: 25vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5%;
}

.footericon {
    margin: 0.5vw;
    font-size: 2vw;
    color: #438c68;
}

.footericon:hover {
    color: #14492a;
}

@media only screen and (max-width: 1000px) {
    .footer {
        padding: 0;
    }

    .footer p {
        margin: 1vw;
        font-size: 2.5vw;
    }

    .footericon {
        margin: 1vw;
        font-size: 6vw;
    }
}