.aboutusheading {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../Images/AboutUs.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 0 250px rgba(0, 0, 0, 0.5) inset;
}

.aboutusheading h1 {
    font-size: 6vw;
    font-family: "Times New Roman", Times, serif;
    color: white;
}

.intro {
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vw;
}

.intro h2 {
    padding-bottom: 1.5vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3vw;
}

.intro p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    line-height: 1.5;
}

.ourmission {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5vw;
    background-color: #7d928b;
}

.ourmission h2 {
    padding-bottom: 1.5vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3vw;
}

.mission {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2vh;
}

.aboutusicon {
    width: 25%;
    font-size: 3vw;
    color: #a84c5c;
}

.missioncontent {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.missioncontent h3 {
    padding-bottom: 1vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5vw;
    text-align: left;
}

.missioncontent li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9vw;
    text-align: left;
}

.objectives {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
}

.objectives h3 {
    padding-bottom: 2vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
}

.objectives li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9vw;
    text-align: left;
}

@media only screen and (max-width: 1000px) {
    .aboutusheading {
        background-position: center;
    }

    .aboutusheading h1 {
        font-size: 10vw;
    }

    .intro {
        height: 30vh;
    }

    .intro h2 {
        font-size: 6vw;
    }

    .intro p {
        font-size: 3vw;
    }

    .ourmission {
        padding-top: 3vh;
        padding-bottom: 0vh;
        padding-left: 0vw;
        padding-right: 0vw;
    }

    .ourmission h2 {
        font-size: 6vw;
    }

    .mission {
        width: 75%;
    }

    .aboutusicon {
        font-size: 10vw;
    }

    .missioncontent h3 {
        font-size: 3vw;
    }

    .missioncontent li {
        font-size: 3vw;
    }

    .objectives {
        flex-direction: column;
        align-items: center;
        padding: 10vw;
    }

    .lists {
        width: 75%;
        padding: 2.5vh;
        padding-left: 0vh;
        padding-right: 0vh;
    }

    .objectives h3 {
        font-size: 4vw;
    }

    .objectives li {
        font-size: 3vw;
    }
}